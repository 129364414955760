import React from "react";
import { Outlet } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Form,
  Nav,
  Navbar,
  NavDropdown,
  Row,
} from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { IoQrCode } from "react-icons/io5";
import { BiWorld } from "react-icons/bi";
import { IoMdMoon } from "react-icons/io";
import { GiMoonBats } from "react-icons/gi";

export default function MainLayout() {
  return (
    <div data-bs-theme="dark">
      {/* nav bar */}
      <Navbar expand="lg">
        <Container className="max-w-[100%]">
          <Navbar.Brand href="#home" className="text-primary">
            <GiMoonBats style={{ width: 25, height: 25 }} /> Moontrade
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            className="text-primary"
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav>
              <Nav.Link href="#">Buy Crypto</Nav.Link>
              <Nav.Link href="#">Markets</Nav.Link>
              <NavDropdown title="Trade" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">
                  Something
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                  Separated link
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="#">Futures</Nav.Link>
              <Nav.Link href="#">Earn</Nav.Link>
              <NavDropdown title="Square" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">
                  Something
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                  Separated link
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="More" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">
                  Something
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                  Separated link
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <div className="flex-1" />
            <Form inline className="flex items-center gap-2 mr-4">
              <Form.Control type="text" placeholder="Search" size="sm" />
              <Button type="submit" size="sm" variant="secondary">
                <FaSearch />
              </Button>
            </Form>
            <div className="flex items-center gap-2">
              <Button type="submit" size="sm" variant="secondary">
                Login In
              </Button>
              <Button type="submit" size="sm">
                Sign Up
              </Button>
              <Button
                type="submit"
                size="sm"
                variant="outline"
                className="hover:text-primary"
              >
                <IoQrCode />
              </Button>
              <Button
                type="submit"
                size="sm"
                variant="outline"
                className="hover:text-primary"
              >
                <BiWorld />
              </Button>
              <Button
                type="submit"
                size="sm"
                variant="outline"
                className="hover:text-primary"
              >
                <IoMdMoon />
              </Button>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* content */}
      <Outlet />
    </div>
  );
}
