import React from "react";
import Routes from "./routes";
import { SocketProvider, StateProvider } from "./stores";
import "./index.scss";

function App() {
  return (
    <SocketProvider>
      <StateProvider>
        <Routes />
      </StateProvider>
    </SocketProvider>
  );
}
export default App;
