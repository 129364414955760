import React from "react";
import { useRoutes } from "react-router-dom";
import _404 from "../pages/404";
import HomePage from "../pages/HomePage";
import MainLayout from "../layouts/MainLayout";
import OptionPage from "../pages/trade/OptionPage";
// import LocRoutes from "./Loc.routes";

function Router() {
  return useRoutes([
    {
      path: "/",
      element: <MainLayout />,
      children: [
        {
          path: "",
          element: <HomePage />,
        },
        {
          path: "option",
          element: <OptionPage />,
        },
      ],
    },
    // LocRoutes,
    {
      path: "*",
      element: <_404 />,
    },
  ]);
}

export default Router;
