import React from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Nav,
  Navbar,
  NavDropdown,
  Row,
} from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { IoQrCode } from "react-icons/io5";
import { BiWorld } from "react-icons/bi";
import { IoMdMoon } from "react-icons/io";
import { GiMoonBats } from "react-icons/gi";

export default function HomePage() {
  return (
    <div>

      {/* content */}
      <Container>
        <div className="flex h-[800px] items-center">
          <div className="flex-1">
            <h1 className="text-primary font-bold" style={{ fontSize: 80 }}>
              100,000,000
            </h1>
            <h1 className="text-neutral-50 font-bold" style={{ fontSize: 80 }}>
              USERS
              <br />
              TRUST US
            </h1>
            <Form
              inline
              className="flex items-center gap-2"
              style={{ width: 500 }}
            >
              <Form.Control
                type="text"
                placeholder="Email/Phone number"
                size="lg"
                className="text-neutral-50"
              />
              <Button
                type="submit"
                size="lg"
                variant="primary"
                style={{ minWidth: 150 }}
              >
                Sign Up
              </Button>
            </Form>
          </div>
          <div className="flex-1 flex h-100 items-center justify-center">
            <img
              src="https://www.ft.com/__origami/service/image/v2/images/raw/ftcms%3A5b6adf89-45c7-46ea-a466-fe8616f32b16?source=next-article&fit=scale-down&quality=highest&width=1440&dpr=1"
              alt=""
              style={{
                width: 600,
                height: 500,
                objectFit: "cover",
                borderRadius: 12,
              }}
            />
          </div>
        </div>
      </Container>

      {/* section 2 */}
      <Container>
        <div className="flex">
          <div className="flex-1 flex justify-center items-center">
            <img src="/images/demo-app.svg" alt="" style={{ width: 250 }} />
          </div>
          <div className="flex-1">
            <h2>Trade on the go. Anywhere, anytime.</h2>
          </div>
        </div>
      </Container>
    </div>
  );
}
