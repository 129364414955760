import React, { useEffect, useRef } from "react";
import { priceData } from "./priceData";
// import { areaData } from './areaData';
import { volumeData } from "./volumeData";

import { createChart, CrosshairMode } from "lightweight-charts";
import { Button } from "react-bootstrap";
// icons
import { MdCandlestickChart } from "react-icons/md";
import { BsGrid1X2Fill } from "react-icons/bs";
import { FaBitcoin } from "react-icons/fa";
import { useSocket } from "../../stores";

export default function OptionPage() {
  const socket = useSocket();

  const chartContainerRef = useRef();
  const chart = useRef();
  const Candlestick = useRef();
  const Volume = useRef();
  const resizeObserver = useRef();
  useEffect(() => {
    if (socket) {
      socket.on("connect", () => {
        socket.emit("joinGroup", "ETHUSDT");
        //   socket.emit("joinGroup", "ETCUSDT");
        console.log("connecting");
      });

      // Example of listening to an event
      socket.on("ticker", (value) => {
        console.log(value);
        const formatData = {
          time: value.startTime,
          open: parseFloat(value.open),
          high: parseFloat(value.high),
          low: parseFloat(value.low),
          close: parseFloat(value.close),
        };
        const formatV = {
          time: value.startTime,
          value: parseFloat(value.volume),
        };
        Volume.current.update(formatV);
        Candlestick.current.update(formatData);
      });

      socket.on("connect_error", (err) => {
        console.error("Connection Error:", err);
      });
      // Cleanup the listener on unmount
      return () => {
        socket.off("connect");
        socket.off("connect_error");
      };
    }
  }, [socket]);
  useEffect(() => {
    chart.current = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: 500, //"300px", //chartContainerRef.current.clientHeight,
      layout: {
        backgroundColor: "rgba(255, 255, 255, 0)",
        textColor: "rgba(255, 255, 255, 0.9)",
      },
      grid: {
        vertLines: {
          color: "#334158",
        },
        horzLines: {
          color: "#334158",
        },
      },
      crosshair: {
        mode: CrosshairMode.Normal,
      },
      priceScale: {
        borderColor: "#485c7b",
      },
      timeScale: {
        borderColor: "#485c7b",
      },
    });

    Candlestick.current = chart.current.addCandlestickSeries({
      upColor: "#4bffb5",
      downColor: "#ff4976",
      borderDownColor: "#ff4976",
      borderUpColor: "#4bffb5",
      wickDownColor: "#838ca1",
      wickUpColor: "#838ca1",
    });
    Candlestick.current.setData([]);
    Volume.current = chart.current.addHistogramSeries({
      color: "rgba(239,185,11,0.6)",
      lineWidth: 2,
      priceFormat: {
        type: "volume",
      },
      overlay: true,
      scaleMargins: {
        top: 0.8,
        bottom: 0,
      },
    });
    Volume.current.setData([]);
  }, []);

  // Resize chart on container resizes.
  //   useEffect(() => {
  //     resizeObserver.current = new ResizeObserver((entries) => {
  //       const { width, height } = entries[0].contentRect;
  //       chart.current.applyOptions({ width, height });
  //       setTimeout(() => {
  //         chart.current.timeScale().fitContent();
  //       }, 0);
  //     });

  //     resizeObserver.current.observe(chartContainerRef.current);

  //     return () => resizeObserver.current.disconnect();
  //   }, []);

  return (
    <div>
      <div
        className="p-2 w-full flex gap-2 border-neutral-400"
        style={{ borderBottomWidth: 1, borderBottomStyle: "solid" }}
      >
        <Button variant="outline" className="hover:text-primary">
          <BsGrid1X2Fill />
        </Button>
        <Button>
          <MdCandlestickChart />
        </Button>
        <div style={{ width: 1 }} className="bg-slate-400" />
        {[...new Array(10)].map((e, i) => (
          <div className="flex items-center gap-2" key={i}>
            <div className="text-yellow-400">
              <FaBitcoin style={{ width: 24, height: 24 }} />
            </div>
            <div className="flex flex-col text-neutral-400">
              <span style={{ fontSize: 12, fontWeight: 700 }}>BTCUSDT</span>
              <span className="text-red-500" style={{ fontSize: 12 }}>
                64,143.09
              </span>
            </div>
          </div>
        ))}
      </div>
      <div className="grid grid-cols-2">
        <div ref={chartContainerRef} className="chart-container" />
        <div className="grid grid-cols-[1fr_auto]">
          <div>teksotksdf</div>
          <div
            className="border-neutral-400 p-4"
            style={{ borderLeftWidth: 1, borderLeftStyle: "solid", width: 280 }}
          >
            <Button
              variant="outline"
              className="bg-green-600 text-neutral-50 w-full"
            >
              Buy
            </Button>
            <Button
              variant="outline"
              className="bg-red-600 text-neutral-50 w-full"
            >
              Sell
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
